/* seems like there is no other injection point */
.fc-event-main {
  display: flex;
  flex-direction: column;
}

table.fc-col-header {
  height: 3em;
}

.fc .fc-timegrid-slot {
  /*
     * sizes the slots so that 6am - 6pm fits in the calendar without scrolling
     * 60vh is the contentheight
     * 3em accounts for header
     * divided by necessary slots
     */
  height: calc((60vh + 3em) / 28);
}
