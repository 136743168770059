a {
  text-decoration: none;
  color: black;
}

* {
  &:focus {
    outline: none;
  }
}
